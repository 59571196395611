<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Điều chỉnh chương trình Xúc tiến đầu tư của Bộ, cơ quan ngang Bộ
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="4">
              <CInput label="Số văn bản" horizontal :value.sync="filterModel.soVanBan"/>
            </CCol>
            <CCol lg="6">
              <CSelect  label="Năm chương trình" horizontal :options="listYear" :value.sync="filterModel.nam" />
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="4">
              <CSelect label="Trạng thái" horizontal :options="optionsTrangThai" :value.sync="filterModel.trangThai"/>
            </CCol>
<!--            <CCol lg="6">-->
<!--              <CInput label="Tên Bộ/UBND Tỉnh" horizontal :value.sync="filterModel.tenBo_UBNDTinh"/>-->
<!--            </CCol>-->
          </CRow>
          <CRow>
            <CCol col="12">
              <CButton class="mb-3 mr-3" color="primary" :disabled="isFetchingList" @click="search">
                <CIcon name="cil-search"/>&nbsp;Tìm kiếm
              </CButton>
              <CButton class="mb-3" color="primary" @click="createItem">
                <CIcon name="cil-plus"/> Thêm mới
              </CButton>
            </CCol>
          </CRow>
          <div class="position-relative table-responsive">
            <table id="tbThongBaoKeHoach" class="table table-striped table-fixed table-hover table-bordered">
              <thead>
              <tr>
                <th class="position-relative pr-4">STT</th>
                <th class="position-relative pr-4">Số văn bản</th>
                <th class="position-relative pr-4">Năm</th>
<!--                <th class="position-relative pr-4">Tình trạng</th>-->
                <th class="position-relative pr-4">Ngày văn bản</th>
                <th class="position-relative pr-4">Số lần hiệu chỉnh</th>
                <th class="position-relative pr-4">Trạng thái</th>
                <th class="position-relative pr-4">Thao tác</th>
              </tr>
              </thead>
              <tbody class="position-relative">
              <template v-if="dataSourcePaging.items">
                <tr v-for="(item, index) in dataSourcePaging.items" :key="item.id">
                  <td>{{ index + firstItemIndex + 1 }}</td>
                  <td>{{ item.soVanBan }}</td>
                  <td>{{ item.nam_ChuongTrinh }}</td>
<!--                  <td>{{ item.tinhTrang }}</td>-->
                  <td>{{ formatDateData(item.createdDate) }}</td>
                  <td>{{ item.soLanHieuChinh }}</td>
                  <td>
                    <template v-if="item.trangThai === 0">Tất cả</template>
                    <template v-if="item.trangThai === 1">Soạn thảo</template>
                    <template v-if="item.trangThai === 2">Chờ phê duyệt</template>
                    <template v-if="item.trangThai === 3">Đã phê duyệt</template>
                    <template v-if="item.trangThai === 4">Yêu cầu chỉnh sửa</template>
                  </td>
                  <td>
                    <CButton size="sm" color="info" class="mr-2" @click="viewItem(item)" title="Chi tiết">
                      <CIcon name="cil-options"/>
                    </CButton>
                    <CButton size="sm" color="primary" class="mr-2" @click="updateItem(item)" title="Chỉnh sửa">
                      <CIcon name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="secondary" class="mr-2" @click="confirmApproveItem(item)" title="Phê duyệt">
                      <CIcon name="cil-settings"/>
                    </CButton>
                    <CButton size="sm" color="danger" @click="destroyItem(item)" title="Xóa">
                      <CIcon name="cil-x"/>
                    </CButton>
                  </td>
                </tr>
              </template>
              <tr v-if="dataSourcePaging.items && dataSourcePaging.items.length === 0 && !isFetchingList">
                <td colspan="8">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        Không có dữ liệu
                        <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                      </h2>
                    </div>
                  </slot>
                </td>
              </tr>
              </tbody>
            </table>
            <CElementCover v-if="isFetchingList"
                           :boundaries="[{ sides: ['top'], query: 'td' },{ sides: ['bottom'], query: 'tbody' }]"/>
          </div>
        </CCardBody>
      </CCard>
      <DeleteConfirmationModal
        title="Xóa đề xuất chương trình xúc tiến đầu tư của Bộ, cơ quan ngang Bộ, UBND Tỉnh"
        :show.sync="isDeleteConfirmationModalOpen"
        :is-deleting="isDeleting"
        :item="item" @confirm-destroy="confirmDestroy"/>
      <ApproveConfirmationModal2
        title="Phê duyệt đề xuất chương trình xúc tiến đầu tư của Bộ, cơ quan ngang Bộ, UBND Tỉnh"
        :show.sync="isApproveConfirmationModalOpen"
        :is-approving="isApproving"
        :item="approvingItem" @confirm-approve="confirmApprove"
        v-on:data="data($event)"
        :type="type"
      />
    </CCol>
  </CRow>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import { mapActions, mapGetters } from 'vuex'
import { displayLabel, loaiDonVis } from '@/shared/appConstants'
import { enums } from '@/shared/enums'
import {
  APPROVE_DEXUATCTXTDT,
  DELETE_DEXUATCTXTDT
} from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import moment from 'moment'

export default {
  name: 'DeXuatCTXTDT',
  components: {
  },
  data () {
    return {
      filterModel: {
        soVanBan: '',
        trangThai: 0,
        nam: Number(this.getYear(new Date())),
        // tenBo_UBNDTinh: '',
        orderBy: null,
        pageNumber: 1,
        pageSize: 15
      },
      optionsLoaiDonVi: loaiDonVis,
      optionsTrangThai: enums.trangThaiDeXuatCTXTDT,
      datePickerConfigs: {
        format: 'DD/MM/YYYY',
        lang: {
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            // MMM
            monthsShort: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            // dddd
            weekdays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
            // ddd
            weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
            // dd
            weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
          }
        },
        clearable: true,
        inline: true,
        editable: false
      },
      tuNgay: '',
      denNgay: '',
      listItem: [],
      listYear: [],
      isDeleteConfirmationModalOpen: false,
      isApproveConfirmationModalOpen: false,
      approvingItem: {},
      type: 'DeXuatCTXTDT_BoNganh',
      approvingItemData: {
        loaiPheDuyet: 0,
        noiDung: '',
        dinhKem: '',
        dexuatguid: 0,
        id: 0
      }
    }
  },
  computed: {
    // ...mapGetters('deXuatCTXTDT', {
    //   dataSourcePaging: 'dataSourcePaging',
    //   isFetchingList: 'isFetchingList',
    //   deletedItem: 'deletedItem',
    //   isDeleting: 'isDeleting',
    //   isDeleteConfirmationModalOpen: 'isDeleteConfirmationModalOpen',
    //   responseResult: 'responseResult'
    // })
    ...mapGetters('deXuatCTXTDT', {
      dataSourcePaging: 'dataSourcePagingDieuChinh',
      isFetchingList: 'isFetchingListDieuChinh',
      responseResult: 'responseResult',
      isDeleting: 'isDeleting',
      item: 'detailItem',
      isApproving: 'isApproving'
    }),
    firstItemIndex () {
      return (this.filterModel.pageNumber - 1) * this.filterModel.pageSize || 0
    }
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      getListPaging: types.GET_LIST_DEXUATCTXTDT_DIEUCHINH_PAGING,
      deleteItem: DELETE_DEXUATCTXTDT,
      approveItem: APPROVE_DEXUATCTXTDT
    }),
    displayLabel,
    async fetchListItem () {
      await this.getListPaging(this.filterModel)
    },
    displayTinhTrangDeXuat (inputDate) {
      // const input = new Date(inputDate)
      // const conHan = new Date(this.getYear(new Date()), 5, 30)
      const input = moment(inputDate)
      const namInput = Number(this.getYear(inputDate))
      const ngayHan = moment([namInput, 5, 30])
      const tuHan = moment([namInput, 5, 27])
      const hetHan = moment([namInput, 5, 30])
      // console.log(input.diff(ngayHan, 'days'))
      if (input.diff(ngayHan, 'days') < 0) {
        return 'Còn hạn'
      } else if (input.diff(tuHan, 'days') >= 0 && input.diff(hetHan, 'days') <= 0) {
        return 'Sắp đến hạn'
      } else return `Quá hạn ${input.diff(ngayHan, 'days')} ngày`
      // else if (input.diff(ngayHan, 'days') > 0) {
      //   return `Quá hạn ${input.diff(ngayHan, 'days')} ngày`
      // }
    },
    data (event) {
      this.approvingItemData.dexuatguid = event.dexuatguid
      this.approvingItemData.loaiPheDuyet = event.loaiPheDuyet
      this.approvingItemData.noiDung = event.noiDung
      this.approvingItemData.dinhKem = event.dinhKem
      this.approvingItemData.id = event.id
    },
    getListYear () {
      const thisyear = Number(this.getYear(new Date()))
      const startyear = 2000
      const endyear = thisyear + 10
      for (let i = startyear; i <= endyear; i++) {
        const it = {
          label: i,
          value: i
        }
        this.listYear.push(it)
      }
    },
    async search () {
      this.filterModel.pageNumber = 1
      this.filterModel.orderBy = null
      await this.fetchListItem()
    },
    createItem () {
      this.$router.push({ path: 'de-xuat-ct-xtdt/them-moi' })
    },
    viewItem (item) {
      this.$router.push({ path: `de-xuat-ct-xtdt/${item.id}` })
    },
    updateItem (item) {
      this.$router.push({ path: `de-xuat-ct-xtdt/${item.id}/cap-nhat` })
    },
    destroyItem (item) {
      this.deletedItem = Object.assign({}, item)
      this.isDeleteConfirmationModalOpen = true
    },
    async confirmDestroy () {
      await this.deleteItem(this.deletedItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    confirmApproveItem (item) {
      this.approvingItem = Object.assign({}, item)
      this.isApproveConfirmationModalOpen = true
    },
    async confirmApprove () {
      await this.approveItem(this.approvingItemData)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isApproveConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    },
    getYear: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    await this.fetchListItem()
    this.getListYear()
    this.displayTinhTrangDeXuat(new Date())
  }
}
</script>
